import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"

//import Layout from "../components/layout"
import Seo from "../components/seo"

const PortfolioPage = ({ data }) => {
  const { allMarkdownRemark } = data
  const displayURL = url => url.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "")
  return (
    <>
      <Seo title="Portfolio" />
      <h1>Portfolio</h1>
      {allMarkdownRemark.edges.map(({ node }) => (
        <div className="blog-post-container" key={node.id}>
          <div className="blog-post">
            <h2>{node.frontmatter.title}</h2>
            <div className="port-header port-small">
              Published {node.frontmatter.date}
            </div>
            <GatsbyImage
              className="port-image"
              image={getImage(node.frontmatter.image)}
              alt=""
              onClick={e => {
                e.preventDefault()
                //window.location.href = node.frontmatter.source
                window.open(node.frontmatter.source, "_blank", "noopener")
              }}
            />
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: node.html }}
            />
            <div className="port-inset">
              <div>
                <span className="port-header">Stack: </span>
                {node.frontmatter.stack}
              </div>
              <div>
                <span className="port-header">Platform: </span>
                {node.frontmatter.platform}
              </div>
              {node.frontmatter.live_on && (
                <div className="port-header">
                  Live on:{" "}
                  <a className="port-small" href={node.frontmatter.live_on}>
                    {displayURL(node.frontmatter.live_on)}
                  </a>
                </div>
              )}
              <div className="port-header">
                Source code:{" "}
                <a className="port-small" href={node.frontmatter.source}>
                  {displayURL(node.frontmatter.source)}
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default PortfolioPage

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            date
            stack
            source
            platform
            live_on
            image {
              childImageSharp {
                gatsbyImageData(width: 300, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
